<template>
  <nav class="topbar-container">
    <v-btn
      v-show="(this.$route.name !== 'home') & (this.$route.name !== 'coupon')"
      @click="handleBack"
      icon
      color="#333333"
    >
      <v-icon large>mdi-chevron-left</v-icon>
    </v-btn>
    <div v-if="this.$route.name == 'coupon'" class="topbar-inner">รับคูปอง</div>
    <div v-else-if="this.$route.name == 'detail_donate'" class="topbar-inner">เลือกคูปอง</div>
    <div v-else class="topbar-inner">ร่วมบริจาค</div>
  </nav>
</template>

<script>
export default {
  methods: {
    handleBack() {
      const prevRoute = {
        agency: "home",
        donation: "agency",
        detail_donate: "agency",
        donatecoupondetail: "detail_donate"
      };
      this.$router.push({ name: prevRoute[this.$route.name] });
      // this.$router.back();
    }
  }
};
</script>

<style scoped>
.topbar-container {
  display: flex;
  height: 70px;
  padding: 0 24px;
  align-items: center;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.25);
}
.topbar-inner {
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
  color: #333333;
}
</style>
