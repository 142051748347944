<template>
  <div>
    <div v-if="title" class="dialog-title">
      {{ title }}
    </div>
    <div v-if="type === 'success'" class="image-container">
      <sweetalert-icon icon="success" />
    </div>
    <div v-else-if="type === 'error'" class="image-container">
      <sweetalert-icon icon="error" />
    </div>
    <div v-else-if="type === 'warning'" class="image-container">
      <sweetalert-icon icon="warning" />
    </div>
    <div v-else-if="type === 'info'" class="image-container">
      <sweetalert-icon icon="info" />
    </div>
    <div v-else-if="type === 'loading'" class="image-container">
      <sweetalert-icon icon="loading" />
    </div>
    <div v-else-if="type === 'general' && image" class="image-container">
      <img :src="image" />
    </div>
    <div v-else-if="type === 'general' && !image && icon">
      <v-icon style="font-size:48px">mdi-account-multiple</v-icon>
    </div>
    <div class="card-text-desc" v-if="desc">{{ desc }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {},
    image: {},
    desc: {},
    icon: {},
    type: {
      default: () => "general"
    }
  }
};
</script>

<style scoped>
.dialog-title {
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 10px 10px;
}
.image-container {
  display: flex;
  justify-content: center;
}
.card-text-desc {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #757575;
}
</style>
