<template>
  <div v-if="coupons.length > 0">
    <v-row align="center" justify="center" v-for="(item, index) in coupons" :key="index">
      <v-col cols="12">
        <v-card class="card_coupon">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="12">
              <v-col cols="12">
                <v-img
                  :src="
                    item.couponDetail.couponImagePath ? item.couponDetail.couponImagePath : image
                  "
                ></v-img>
              </v-col>
              <v-row justify="center" no-gutters class="mt-4">
                <v-col cols="12" class="line"></v-col
              ></v-row>
              <v-col cols="12" class="mt-2 ml-2">
                <span>{{ item.couponDetail.couponDetail.couponName }}</span>
              </v-col>
              <v-col cols="12" class="ml-2" style="margin-top: -15px">
                <span class="font_detail">{{
                  item.couponDetail.couponDetail.couponDescription
                }}</span>
              </v-col>
              <v-col cols="12" align="center" style="margin-top: -10px">
                <v-btn color="primary" outlined width="95%" height="40px" @click="goTo(item)">
                  <span class="font_select">เลือก</span>
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-else style="display:flex; justify-content: center">ไม่มีคูปองบริจาค</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      detail:
        "สมาคมอาสาสร้างสุข ชวนท่านร่วมปันอีก 1 มื้อ เพื่อช่วย เหลือผู้ยากไร้ให้รอด ในรูปแบบของอาหารปรุงสำเร็จ",
      image: require("../../assets/image/coupon.png"),
      card_for: 5,
      id: null
    };
  },
  watch: {},
  computed: {
    ...mapState("coupon", ["coupons"])
  },
  created() {},
  mounted() {
    this.getCoupons(this.$route.params.id);
  },
  methods: {
    ...mapActions("coupon", ["getCoupons", "selectCoupon"]),
    goTo(val) {
      this.selectCoupon(val);
      this.$router.push(`/donationcoupon/${this.$route.params.id}/detail`);
    }
  }
};
</script>

<style scoped>
.card_coupon {
  border-radius: 4px !important;
  /* color: #fff; */
  background-color: #fff;
}
.tag {
  /* width: 154px;
  height: 48px;
  
  background-color: #D32F2F;
  border-radius: 12px 6px 6px 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; */
  width: 0;
  height: 0;
  border-left: 0px solid #d32f2f;
  border-right: 80px solid transparent;
  border-bottom: 0px solid transparent;
  border-top: 80px solid #d32f2f;
  position: absolute;
  z-index: 2;
}
.font_type {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 2;
  margin-top: -70px;
}
.font_select {
  color: #1e4d94;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.font_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #aeaeae;
}
.btn_select {
  width: 90%;
  height: 40px;
  color: #1e4d94;
}
.font_detail {
  color: #aeaeae;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.line {
  position: absolute;
  color: #6594e0;
  border-bottom: 2px dashed #e0e0e0;
  width: 96%;
}
</style>
