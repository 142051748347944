<template>
  <v-card
    class="i-card"
    flat
    :style="{
      background: '#fff',
      padding: '16px',
      borderRadius: '16px',
      position: 'relative',
      overflow: 'hidden'
    }"
    :outlined="outlined"
  >
    <slot />
  </v-card>
</template>

<script>
export default {
  props: {
    outlined: {
      default: () => false
    }
  }
};
</script>

<style>
.i-card.v-card.v-sheet--outlined {
  border: thin solid rgba(51, 51, 51, 0.25) !important;
}
</style>
