<template>
  <div>
    <div class="agency-image-container">
      <div class="agency-image">
        <img :src="localImage" height="100%" width="100%" />
      </div>
    </div>
    <div class="agency-detail">
      <i-card outlined>
        <div>
          <div class="agency-group-content">
            <div class="agency-title">ชื่อโครงการ</div>
            <div class="agency-sub-title">
              {{ agency.name }}
            </div>
          </div>
          <div class="agency-group-content">
            <div class="agency-title">วันที่สิ้นสุดกิจกรรม</div>
            <div class="agency-sub-title">{{ datePreview }}</div>
          </div>
          <div class="agency-group-content">
            <div class="agency-title">รายละเอียดการร่วมบริจาค</div>
            <div class="agency-sub-title">
              {{ agency.details }}
            </div>
          </div>
        </div>
      </i-card>
    </div>
    <div class="detail-action">
      <i-button style="height: 45px" @click="handleClick" :disabled="canDonate"
        >ร่วมบริจาค</i-button
      >
    </div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      class="agency-dialog-head"
      content-class="bottom-dialog-hey"
      scrollable
      full-width
      transition="dialog-bottom-transition"
    >
      <v-card style="position: absolute; left: 0; bottom: 0;">
        <v-card-title style="font-size: 16px; align-self: center;" class="agency-dialog-title pb-0">
          เลือกรูปแบบบริจาค
          <v-spacer></v-spacer>
        </v-card-title>
        <v-list>
          <v-list-item-group color="primary">
            <v-list-item
              style="border: 1px solid #1E4D94;box-sizing: border-box;border-radius: 6px;"
              class="ma-5"
            >
              <v-list-item-content
                style="justify-content: center; color: #1E4D94"
                @click="ClickLocalLife"
                >บริจาคแต้ม (Local Life)</v-list-item-content
              >
            </v-list-item>
            <v-list-item
              style="border: 1px solid #1E4D94;box-sizing: border-box;border-radius: 6px; "
              class="ma-5"
            >
              <v-list-item-content 
                style="justify-content: center; color: #1E4D94"
                @click="ClickThaiCom"
                >ซื้อแต้มบริจาค</v-list-item-content
              >
            </v-list-item>
            <v-list-item
              style="border: 1px solid #1E4D94;box-sizing: border-box;border-radius: 6px; "
              class="ma-5"
              v-if="agency.isCouponDonate"
            >
              <v-list-item-content
                style="justify-content: center; color: #1E4D94"
                @click="ClickCoupon"
                >ซื้อคูปองบริจาค</v-list-item-content
              >
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>
    <!-- dialog -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  data: () => ({
    image: require("assets/image/preview.png"),
    dialog: false
  }),
  async mounted() {
    this.getPoint();
    await this.getAgency(this.$route.params.id);
  },
  computed: {
    ...mapState("agency", ["isFetching", "agency"]),
    ...mapState("user", ["userDonatePoint", "userPoint"]),
    localImage() {
      const baseUrl = process.env.VUE_APP_BASE_URL_API;
      const image = require("assets/image/preview.png");
      return this.agency.image ? `${baseUrl}/media/image${this.agency.image}` : image;
    },
    localStartDate() {
      return this.agency.startTime ? moment(this.agency.startTime).format("DD/MM/YYYY") : "";
    },
    localEndDate() {
      return this.agency.endTime
        ? moment(this.agency.endTime).format("DD/MM/YYYY")
        : "ไม่ระบุวันสิ้นสุด";
    },
    datePreview() {
      return this.localStartDate + `${this.localEndDate ? ` - ${this.localEndDate}` : ""}`;
    },
    canDonate() {
      return false;
    }
  },
  methods: {
    ...mapActions("agency", ["getAgency"]),
    ...mapActions("user", ["getPoint"]),
    handleClick() {
      this.dialog = true;
      // if (this.userDonatePoint.points > 0 && this.userPoint.points > 0) {
      //   this.dialog = true;
      // } else if (this.userPoint.points > 0) {
      //   this.$router.push(`/donation/${this.$route.params.id}/point`);
      // } else if (this.userDonatePoint.points > 0) {
      //   this.$router.push(`/donation/${this.$route.params.id}/donatepoint`);
      // }
    },
    ClickLocalLife() {
      console.log("Local Life");
      this.dialog = false;
      this.$router.push(`/donation/${this.$route.params.id}/point`);
    },
    ClickThaiCom() {
      console.log("ThaiCom");
      this.dialog = false;
      this.$router.push(`/donation/${this.$route.params.id}/donatepoint`);
    },
    ClickCoupon(){
      this.dialog = false;
      this.$router.push(`/donationcoupon/${this.$route.params.id}`);
      // this.$router.push(`/detail_donate`);
    }
  }
};
</script>

<style scoped>
.agency-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.agency-image {
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
}
.agency-detail {
  margin-top: 10px;
}
.agency-group-content {
  margin: 15px 0;
}
.agency-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  /* margin: 5px 0; */
  margin-bottom: 15px;
}
.agency-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #aeaeae;
  margin: 5px 0;
}
</style>
