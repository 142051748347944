<template>
  <div></div>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  async mounted() {
    this.$dialog({
      showCancel: false,
      okLabel: "กลับหน้าหลัก",
      image: require("assets/thank-icon.svg"),
      desc: "ขอบคุณที่ร่วมบริจาคในครั้งนี้"
    }).then(() => {
      this.$router.push("/");
    });
  }
};
</script>

<style scoped></style>
