<template>
  <v-btn
    depressed
    :style="{
      background: 'linear-gradient(93.94deg, #1E4D94 0%, #55B8DC 100%)',
      color: '#fff',
      borderRadius: '8px'
    }"
    :disabled="disabled"
    :block="block"
    @click="e => $emit('click', e)"
    :loading="loading"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    block: {
      default: () => true
    },
    loading: {
      default: () => false
    },
    disabled: {
      default: () => false
    }
  }
};
</script>
