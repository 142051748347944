import actions from "./actions";
import mutations from "./mutations";

const state = {
  agencies: [],
  agency: {},
  isFetching: false,
  error: null
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
