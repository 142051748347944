import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import vuetify from "plugins/vuetify";
import DialogPlugin from "plugins/dialog";
import Button from "components/button.vue";
import Card from "components/card/index.vue";
import TextField from "components/inputs/text-field.vue";

import SweetAlertIcons from "vue-sweetalert-icons";

import "layouts/index";

Vue.config.productionTip = false;

Vue.component("i-button", Button);
Vue.component("i-card", Card);
Vue.component("i-text-field", TextField);

Vue.use(DialogPlugin);

Vue.use(SweetAlertIcons);

export const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
