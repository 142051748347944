import { GET_API, GET_AGENCIES_SUCCESS, GET_AGENCY_SUCCESS, GET_FAILURE } from "./constant";

import { getAgenciesService, getAgencyService } from "services/agency.service";

export default {
  getAgencies: async ({ commit }) => {
    try {
      commit(GET_API);
      const result = await getAgenciesService();
      commit(GET_AGENCIES_SUCCESS, result);
    } catch (error) {
      commit(GET_FAILURE, error.message);
    }
  },
  getAgency: async ({ commit }, payload) => {
    try {
      commit(GET_API);
      const result = await getAgencyService(payload);
      commit(GET_AGENCY_SUCCESS, result);
    } catch (error) {
      commit(GET_FAILURE, error.message);
    }
  }
};
