import axios from "clients/axios";

export const getCouponsService = async (id) => {
  try {
    const res = await axios.get(`/donate-coupon/listcoupondonate/${id}`);
    const { data } = res.data;
    return data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
