<template>
  <div>
    <i-text-field v-model="share_token" label="share_token" hide-details></i-text-field>
    <i-button style="height: 45px" @click="handleClick">เปิด</i-button>
    <div v-if="open">
      <MyPoint />
      <br />
      <div class="home-title">หน่วยงานและมูลนิธิ123</div>
      <i-text-field v-model="search" label="ค้นหาหน่วยงานและมูลนิธิ" hide-details></i-text-field>
      <CardList path="agency" :items="list" />
    </div>
    <iframe
      v-else
      class="ifram-containner"
      :src="`https://ngs2staging.nexgenshop.co.th/member/callback-onechat?redirect=coupon_list&share_token=${share_token}`"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
// @ is an alias to /src
import MyPoint from "./modules/my-point";
import CardList from "components/card/card-list.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "home",
  components: {
    MyPoint,
    CardList
  },
  data: () => ({
    search: "",
    list: [],
    share_token: "",
    open: true
  }),
  watch: {
    search(val) {
      this.list = [...this.agencies].filter(
        (a) => (a.name && a.name.includes(val)) || (a.agencyName && a.agencyName.includes(val))
      );
    }
  },
  async mounted() {
    await this.getAgencies();
    this.list = [...this.agencies];
  },
  computed: {
    ...mapState("agency", ["isFetching", "agencies"])
  },
  methods: {
    ...mapActions("agency", ["getAgencies"]),
    handleClick(){
     this.open = false
    }
  }
};
</script>

<style scoped>
.home-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 5px;
}
.ifram-containner {
  width: 100%;
  height: 900px;
}
</style>
