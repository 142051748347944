import { getCouponsService } from "services/coupon.service";

export default {
  getCoupons: async ({ commit }, payload) => {
    try {
      const result = await getCouponsService(payload);
      commit("GET_COUPON_SUCSESS", result);
    } catch (error) {
      console.log(error);
    }
  },
  selectCoupon: ({commit}, payload) => {
    commit("SELECT_COUPON", payload);
  }
};
